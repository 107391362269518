<template>
  <div class="father">
        <div id="scroll" @mouseenter="clearTime()" @mouseleave="leave()" class="item" v-viewer>
          <img v-for="(item,index) in noticeList" :key="index" :src="item" class="logo-img2">
        </div>
  </div>
</template>

<script>

export default {
  name: "cooperationLogo",
  // props:['idx'],
  data(){
    return{
      timer:null,
      noticeList:[
          require('@/assets/index-image/socialAccept/1.jpg'),
          require('@/assets/index-image/socialAccept/2.jpg'),
          require('@/assets/index-image/socialAccept/3.jpg'),
          require('@/assets/index-image/socialAccept/4.jpg'),
          require('@/assets/index-image/socialAccept/5.jpg'),
          require('@/assets/index-image/socialAccept/6.jpg'),
          require('@/assets/index-image/socialAccept/7.jpg'),
          require('@/assets/index-image/socialAccept/8.jpg'),
      ],
    }
  },
  mounted() {
    this.autoSroll("scroll");
  },
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    // 添加自动滚动
    /*
      Id   需要滚动的区域 id名称
    */
    // autoSroll(Id) {
      // flag 为true时停止滚动
      // var flag = false;
      // 定时器
      // var timer;
    autoSroll(Id) {
      var h = -1;
      this.timer =  setInterval(function() {
        // flag = true;
        //获取当前滚动条高度
        var current = document.getElementById(Id).scrollTop;
        if (current == h) {
          //滚动到底端,返回顶端
          h = 0;
          document.getElementById(Id).scrollTop = h + 1;
        } else {
          //以25ms/3.5px的速度滚动
          h = current;
          document.getElementById(Id).scrollTop = h + 1;
        }
      }, 50);
    },
    clearTime(){
      clearInterval(this.timer);
      this.timer = null;
    },
    leave(){
      this.autoSroll("scroll");
    }
  }

}
</script>

<style lang="less" scoped>
.father {
  width: 100%;
  height: 100%;
  padding: 0 1%;

  .item,.item2 {
    width: 100%;
    height: 80%;
    margin: 4% auto;
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-around;
    overflow-y: scroll;

    .logo-img {
      width: 26%;
      height: 88%;
      margin-bottom: 20px;
      margin-left:5%;
      border-radius: 50%;
    }
    .logo-img2 {
      width: 28%;
      height:80%;
      margin-bottom: 20px;
      margin-left: 20px;
    }
  }

  //.item2::-webkit-scrollbar{
  //  background: none;
  //}

  .item::-webkit-scrollbar {/*滚动条整体样式*/
    width: 5px;     /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }
  .item::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: #3a73e8;
  }
  .item::-webkit-scrollbar-track {/*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    border-radius: 10px;
    background: rgb(8,25,79);
  }
}
</style>